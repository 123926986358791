const FJUtil = {
    addEvent: function (element, event, handler, useCapture = false) {
        if (!element) {
            return;
        }
        if (element.addEventListener) {
            element.addEventListener(event, handler, useCapture);
        } else if (element.attachEvent) {
            useCapture
                ? element.attachEvent('on' + event, handler, useCapture)
                : element.attachEvent('on' + event, handler);
        }
    },
    removeEvent: function (element, event, handler, useCapture = false) {
        if (!element) {
            return;
        }
        if (element.removeEventListener) {
            element.removeEventListener(event, handler, useCapture);
        } else if (element.detachEvent) {
            useCapture
                ? element.detachEvent('on' + event, handler, useCapture)
                : element.detachEvent('on' + event, handler);
        }
    },
    convertToNotGonnaCryMeme: function (str) {
        let words = str.split('-');
        let result = '';

        for (let i = 0; i < words.length; i++) {
            let word = words[i];
            let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
            result += ' ' + capitalizedWord;
        }

        return result;
    },
    getHashBuffer: function (source, threshold = 1073741824) {
        let size = source.size ? source.size : source.length;

        // 默认超过1G会进行裁剪
        if (size > threshold) {
            let offset = 0; // Math.floor((size - threshold)/2);
            source = source.slice(offset, offset + threshold);
        }

        if (source.arrayBuffer) {
            return source.arrayBuffer();
        } else {
            return this.convertToArrayBuffer(source);
        }
    },

    convertToArrayBuffer: function (source) {
        return new Promise((resolve, reject) => {
            // source is blob or blob url
            if (source === null) {
                reject(null);
            }
            if (typeof source === 'string') {
                // blob url
                var xhr = new XMLHttpRequest();
                xhr.open('GET', source, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(xhr.response);
                    } else {
                        resolve(null);
                    }
                };
                xhr.onerror = () => {
                    resolve(null);
                };
                xhr.ontimeout = () => {
                    resolve(null);
                };

                xhr.send();
            } else {
                try {
                    const fileReader = new FileReader();
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    };
                    fileReader.onerror = () => {
                        resolve(null);
                    };
                    fileReader.readAsArrayBuffer(source);
                } catch (e) {
                    console.log(e, source);
                    resolve(null);
                }
            }
        });
    },

    /**
     * @description 轮询
     * @param checker - 检查函数
     * @param interval - 轮询间隔时间（ms）
     * @param timeout - 最大轮询时间（ms）
     * @param throwErrorTimeout - 是否在在 timeout 时抛出错误
     * @return {Promise<void>}
     */
    polling: async function (checker, interval = 1, timeout = 2000, throwErrorTimeout) {
        const oldTime = performance.now();
        const isTimeout = () => {
            const out = performance.now() - oldTime >= timeout;
            if (throwErrorTimeout && out) {
                throw new Error('POLLING_TIMEOUT');
            }
            return out;
        };
        while (!checker() && !isTimeout()) {
            await this.wait(interval / 1000);
        }
    },

    wait: function (seconds = 0.3) {
        return new Promise(resolve => {
            setTimeout(resolve, seconds * 1000);
        });
    },

    /**
     * @description 埋点统计
     * @param eventType
     * @param eventData
     */
    ealog: function (eventType, eventData) {
        if (window && window.ealog && window.ealog.addEvent) {
            window.ealog.addEvent(eventType, eventData);
        }
    },

    /**
     * @description 点击是否在区域内
     * @param e 鼠标点击对象
     * @param element 点击区域dom
     * @returns {boolean}
     */
    isPanelArea: function (e, element) {
        const point = {
            x: e.clientX,
            y: e.clientY,
        };
        const boundingBox = element.getBoundingClientRect();
        const rect = {
            x: boundingBox.left,
            y: boundingBox.top,
            width: boundingBox.width,
            height: boundingBox.height,
        };

        return this.isPointInRect(point, rect);
    },

    /**
     * @description 点是否在某个区域内
     * @param {Object} point
     * @param {number} point.x
     * @param {number} point.y
     * @param {Object} rect
     * @param {number} rect.x
     * @param {number} rect.y
     * @param {number} rect.width
     * @param {number} rect.height
     * @return {boolean|boolean}
     */
    isPointInRect: function (point, rect) {
        return (
            point.x >= rect.x && point.x <= rect.x + rect.width && point.y >= rect.y && point.y <= rect.y + rect.height
        );
    },

    /**
     * 视频元素SEO优化方法：
     * 1. 添加类名 。observe-video
     * 2. video标签添加 preload属性
     * 3. autoplay属性设置为false
     * 4. 添加自定义属性 data-visible-play='true'
     * 即可在元素出现时才获取media资源并进行相应操作
     */
    videoVisibleLoad(className = '.observe-video') {
        // 监听的目标元素
        // let video = document.getElementsByTagName('video');
        const video = document.querySelectorAll(className);

        if (!video) {
            return false;
        }

        // 创建一个 IntersectionObserver 实例
        const observer = new IntersectionObserver(entries => {
            // entries 是一个包含所有观察的目标元素及其交叉信息的数组
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    let play = entry.target.dataset['visiblePlay'];
                    if (play === 'true') {
                        entry.target.play();
                    }
                    observer.unobserve(entry.target);
                }
            });
        });

        // 开始观察目标元素
        for (let i = 0; i < video.length; i++) {
            observer.observe(video[i]);
        }

        return observer;
    },
    /**
     * @param time
     * @param keepMs 是否保留毫秒
     * @param mDigit 分钟位数
     * @param msDigit 毫秒位数
     * @param hasAutoHour 是否在超过60分钟后自动转化为小时
     * @returns {string|string}
     */
    formatTime: function (time, keepMs, mDigit, msDigit, hasAutoHour = false) {
        if (!time && time !== 0) return mDigit === 2 ? '00:00' : '0:00';
        if (typeof time !== 'number') time = Number(time);
        if (isNaN(time)) return mDigit === 2 ? '00:00' : '0:00';

        time = time.toFixed(2);
        let m = Math.floor(time / 60);
        let s = keepMs ? Math.floor(time % 60) : Math.round(time % 60); // 显示毫秒的地方s要用向下取整
        // 小时
        let hour = 0;
        if (s === 60) {
            // 四舍五入可能造成s为60
            m += 1;
            s = 0;
        }
        if (s < 10) s = '0' + s;

        if (hasAutoHour && m >= 60) {
            hour = Math.floor(m / 60);
            m = m % 60;
        }
        if (m < 10 && mDigit === 2) m = '0' + m;

        if (keepMs) {
            time = (Math.round(time * 10) / 10).toFixed(2).toString();
            let ms = time.substr(time.lastIndexOf('.') + 1, msDigit || 2);
            if (hour !== 0) {
                return `${hour}:${m}:${s}.${ms}`;
            }
            return `${m}:${s}.${ms}`;
        } else {
            if (hour !== 0) {
                return `${hour}:${m}:${s}`;
            }
            return `${m}:${s}`;
        }
    },
};
export default FJUtil;
