import FJNetworkStore from "../src/store/FJNetworkStore";

let isApiLoading = false;
let isApiLoaded = false;
let script = null;

export default {
    getApiIsLoaded: () => {
        return isApiLoaded
    },

    getApiIsApiLoading: () => {
        return isApiLoading
    },

    loadApi: async (url) => {
        isApiLoading = true;
        isApiLoaded = false;

        if (script) {
            document.body.removeChild(script)
        }

        script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.src = url;

        try {
            await new Promise((resolve, reject) => {
                // IE的script 元素只支持onreadystatechange事件，不支持onload事件。
                // FireFox,Opera, Chorme, IE11+ 和Safari3+的script 元素不支持onreadystatechange事件，只支持onload事件。
                script.onload = () => {
                    isApiLoaded = true;
                    isApiLoading = false;
                    resolve();
                };
                script.onreadystatechange = function () {
                    if (this.readyState === 'complete' || this.readyState === 'loaded') {
                        isApiLoaded = true;
                        isApiLoading = false;
                        resolve();
                    }
                };
                script.onerror = e => {
                    isApiLoaded = false;
                    isApiLoading = false;
                    reject(e);
                };
                document.body.appendChild(script);
            });
            isApiLoading = false;
        } catch (e) {
            isApiLoading = false;
            throw new Error(e);
        }
    },

    openTencentCaptcha: (success, afterTodo) => {
        try {
            const captcha1 = new TencentCaptcha('2080394949', (res) => {
                // res（用户主动关闭验证码）= {ret: 2, ticket: null}
                // res（验证成功） = {ret: 0, ticket: "String", randstr: "String"}
                // console.log(res,'腾讯三方验证')
                if (res.ret === 0) {
                    let ticket = res.ticket
                    let randstr = res.randstr
                    // console.log(res.ticket)   // 票据
                    // console.log(res.randstr)   // 票据
                    success(ticket, randstr)
                    afterTodo('TX clean')
                } else if (res.ret === 2) {
                    afterTodo('TX cancel')
                }
            });
            captcha1.show(); // 显示验证码
        } catch (err) {
            console.log(err);
            afterTodo('TX networkFail');
        }
    },
    openGoogleCaptcha: (success, afterTodo, grcDom) => {
        window.gcIdSitekey = "6LdkDq8UAAAAAJfRAp4Z1taFt1LoIgpX85W0PPEL";
        let gcId = ''
        // if(document.domain == "www.scrapbooking-software.com" || document.domain == "www.designcap.com"){
        //     gcIdSitekey = "6LdSDq8UAAAAAKojMBh6mnLqv7uVHDV3Ql0cT5cv";
        // }else if(document.domain == "www.photoframemaster.com" || document.domain == "www.designevo.com"){
        //     gcIdSitekey = "6LeKzqQUAAAAAEJ6PafM6cSZhhN8ghYC0MRvJpyT";
        // }else if(document.domain == "www.smartvideomaker.com" || document.domain == "www.flexclip.com"){
        //     gcIdSitekey = "6LdkDq8UAAAAAJfRAp4Z1taFt1LoIgpX85W0PPEL";
        // }else if(document.domain == "photocalendarmaker.com" || document.domain == "www.photocalendarmaker.com" || document.domain == "www.fotojet.com"){
        //     gcIdSitekey = "6LcQqJ4UAAAAACrxL3C-eWy8QpqI_F2avNDatfMQ";
        // }else if(document.domain == "www.recordcast.com" || document.domain == "www.swiftscreenrecorder.com"){
        //     gcIdSitekey = "6Lfh6rEZAAAAAE8innRwHZvAfWlTqHg-lHyu8zjU";
        // }else{
        //     console.log("not set site key");
        // }
        if (!grecaptcha) {
            afterTodo('No Grecaptcha');
            return
        }
        try {
            gcId = grecaptcha.render(grcDom, {
                'sitekey': window.gcIdSitekey,
                'size': 'normal',// 尺寸规则，有normal与compact两个值可选
                'callback': (captcha) => {
                    success(captcha)
                    afterTodo('clean')
                },
                'expired-callback': () => {
                    console.log("Error expired");
                    afterTodo('cancel expired')
                },
                'error-callback': (err) => {
                    console.log(err)
                    afterTodo('cancel')
                },
            });
        } catch (err) {
            console.log(err)
            afterTodo('networkFail')
        }
    },
    beginRecaptcha: async function (todo, afterTodo, type) {
        // 当你调用 grecaptcha.render() 时，reCAPTCHA 会将验证码的显示和交互界面渲染到指定的元素中。如果你尝试在已经渲染的元素上再次调用 grecaptcha.render()，就会导致报错，提示"reCAPTCHA has already been rendered in this element"。
        const __updateGrcDom = (id) => {
            const grcDom = document.getElementById(id);
            grcDom.innerHTML = null;
            const cloneGrcDom = grcDom.cloneNode(true);
            const grcDomParent = grcDom.parentNode;
            grcDom.remove();
            grcDomParent.append(cloneGrcDom);
        };

        FJNetworkStore.getUserCountryCode().then((code) => {
            if (code && code === "CN") {
                this.openTencentCaptcha((ticket, randstr) => {
                    // 中国大陆地区返回参数
                    todo({
                        recaptcha: ticket,
                        rt: 'tc',
                        randstr: randstr
                    })
                }, afterTodo)
            } else {
                if (document.getElementById('register-recaptcha')) {
                    document.getElementById('register-recaptcha').innerHTML = null;
                }
                let grcDom = 'register-recaptcha';
                this.openGoogleCaptcha(function (recaptcha) {
                    __updateGrcDom(grcDom)
                    todo({
                        recaptcha: recaptcha,
                        rt: 'gl',
                    })
                }, (msg) => {
                    if (msg !== 'clean') {
                        __updateGrcDom(grcDom)
                    }
                    afterTodo(msg)
                }, grcDom)
            }
        }).catch((e) => {
            console.log(e)
            afterTodo('networkFail');
        })

    }
}
